<template>
  <v-autocomplete
    v-bind="$attrs"
    ref="autocomplete"
    :error="error"
    :color="textColor"
    :placeholder="placeholder"
    :background-color="backgroundColor"
    :class="classDefined"
    :dense="$vuetify.breakpoint.smAndDown"
    :outlined="outlined || isOutline"
    clearable
    :value="value"
    :items="items"
    :loading="loading"
    :rules="required ? basicRule : []"
    hide-selected
    :disabled="isDisabled || items.length === 0"
    v-on="$listeners"
    class="pb-0"
    @click="outlined = true"
    @click:clear="clear"
  >
  </v-autocomplete>
</template>

<script>
export default {
  name: 'autoCompletar',
  props: {
    value: {
      type: [Object, String, Number],
    },
    placeholder: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    textColor: {
      type: String,
      default: 'info',
    },
    classDefined: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOutline: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    error: false,
    outlined: false,
    basicRule: [(v) => !!v || 'Campo Requerido'],
  }),
  mounted() {
    this.setBorderColor();
  },
  methods: {
    clear() {
      this.$emit('input', null);
    },
    get() {
      return this.value;
    },
    set(value) {
      this.$emit('input', value);
    },
    setBorderColor() {
      const element = this.$refs.autocomplete.$el.querySelector('fieldset');
      const component = this.$refs.autocomplete;
      if (!element) return null;

      element.style.borderColor = 'black';

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const isError = Boolean(component.validations.length);

          if (mutation.attributeName === 'class') {
            if (this.isDisabled) {
              element.style.borderColor = '#e3e2e2';
            } else if (this.error || isError) {
              element.style.borderColor = 'red';
            } else {
              element.style.borderColor = '#0032a5';
            }
          }
        });
      });

      observer.observe(element.closest('.v-input'), {attributes: true});
    },
  },
};
</script>

<style scoped></style>
